import RouterDSL from "@ember/routing/-private/router-dsl";
import EmberRouter from "@ember/routing/router";
import compoundSurveyRoutes from "compound-survey/compound-survey/router/compound-survey-routes";
import bloodPressureRoutes from "core/blood-pressure/router/blood-pressure-routes";
import Ember from "ember";
import ENV from "parog-web/config/environment";

export default class Router extends EmberRouter {
  location = ENV.locationType;
  rootURL = ENV.rootURL;
}

Router.map(function () {
  this.route("v2", function () {
    this.route("patient-link-login");
    this.route("patient-password-login");
    this.route("patient-password-forgotten");
    this.route("medic-password-forgotten");
    this.route("medic-login");
  });

  this.route("terms-of-use");
  this.route("view-terms-of-use");
  this.route("openid-redirect");
  this.route("patient-web", function () {
    this.route("login");
    this.route("logout");
    this.route("login-with-account");
    this.route("forgot-password", function () {
      this.route("send-instructions");
      this.route("set-password");
    });
    this.route("protected", { path: "/" }, function () {
      this.route("online-consultation");

      this.route("bio-results", function () {
        this.route("edition");
        this.route("summary");
      });

      this.route("dashboard", function () {
        this.route("declare");
        this.route("consult");
        this.route("learn");
      });

      this.route(
        "form-requested",
        { path: "forms-requested/:form_requested_id" },
        function () {
          this.route("feature", { path: "/:feature" }, function () {
            this.route("component", { path: "/:component" });
          });
        }
      );

      bloodPressureRoutes.call(this);
      compoundSurveyRoutes.call(this);
      resultsTableRoutes.call(this);

      this.route("chat");
      this.route("consult", function () {
        this.route("medical-synthesis");
        this.route("bio-exams");
        this.route("prescriptions");
        this.route("bio-constants");
        this.route("consultations");
      });

      // FORC
      this.route("feature", { path: "/:feature" }, function () {
        this.route("component", { path: "/:component" }, function () {
          this.route("edition", { path: "/:source_je_id" });
        });
      });

      this.route("hla", function () {
        this.route("consult", { path: "consult/:je_id" });
      });
    });
  });

  this.route("medics-web", function () {
    this.route("login");
    this.route("login-sso");
    this.route("login-sso-wise");
    this.route("logout");
    this.route("forgot-password", function () {
      this.route("send-instructions");
      this.route("set-password");
    });

    this.route("protected", { path: "/" }, function () {
      this.route("index", { path: "/" }, function () {
        this.route("alerts-and-notifications");
        this.route("tasks-and-consultations");
        this.route("patient-list");

        this.route("dmp-bulk-download");

        this.route("bio-exams", function () {
          this.route("summary");
          this.route("review", { path: "review/:je_id" });
        });
      });

      this.route("help", function () {
        this.route("dmp");
      });

      this.route("profile");

      this.route("prescriptions");

      this.route("ok-chimio");

      this.route("find-patient", { path: "find-patient/:concatenated_ipp" });

      this.route("patients", function () {
        this.route("new");
        this.route("import");

        this.route("patient", { path: ":patient_id" }, function () {
          this.route("dashboard", function () {
            this.route("main", { path: "/" }, function () {
              // FORC as a modal
              this.route("feature", { path: "/:feature" }, function () {
                this.route("component", { path: "/:component" }, function () {
                  this.route("edition", { path: "/:source_je_id" });
                });
              });

              resultsTableRoutes.call(this);
            });

            this.route("bio-results", function () {
              this.route("summary");
            });
          });
          this.route("healthcare-plan");

          bloodPressureRoutes.call(this);
          compoundSurveyRoutes.call(this);

          this.route("bio-prescription", function () {
            this.route("template-selection");
            this.route("edition-form");
          });

          this.route("medication-prescription", function () {
            this.route("edition-form");
          });

          this.route("bio-results", function () {
            this.route("edition");
          });

          this.route("timeline");
          this.route("administrative", function () {
            this.route("identity-access-logs");
            this.route("disenrollment");
          });
          this.route("documentation-page");
          this.route("edit");

          this.route("threshold-triggers");
          this.route("reimbursement");
          this.route("consultation", function () {
            this.route("report-form");
            this.route("patient-pathway");
          });
          this.route("education", function () {
            this.route("learn");
          });

          this.route("medics");

          this.route(
            "form-requested",
            { path: "forms-requested/:form_requested_id" },
            function () {
              this.route("feature", { path: "/:feature" }, function () {
                this.route("component", { path: "/:component" });
              });
            }
          );

          // DMP routes
          this.route("dmp", function () {
            this.route("documents", function () {
              // eslint-disable-next-line ember/routes-segments-snake-case
              this.route("show", { path: ":uniqueId" });
            });
          });

          // FORC
          this.route("enrollment");
          this.route("feature", { path: "/:feature" }, function () {
            this.route("component", { path: "/:component" }, function () {
              this.route("edition", { path: "/:source_je_id" });
            });
          });

          this.route("hla", function () {
            this.route("consult", { path: "consult/:je_id" });
          });

          this.route("pre-treatment-synthesis");
        });
      });

      this.route("medics", function () {
        this.route("new");

        this.route("medic", { path: ":medic_id" });
      });

      this.route("global-medical-synthesis", function () {
        this.route("download");
      });
    });
  });

  if (Ember.testing) {
    this.route("test-routes", function () {
      this.route("formy");
    });
  }

  // Catches unknown route.
  // @see https://guides.emberjs.com/release/routing/defining-your-routes/#toc_wildcard--globbing-routes
  this.route("not-found", { path: "*" });
});

function resultsTableRoutes(this: RouterDSL) {
  this.route("results-table", function () {
    this.route("temperature");
    this.route("measurements");
    this.route("capillarity-blood-glucose");
    this.route("vems-demm");
    this.route("respiratory-rate");
    this.route("oxygene-saturation");
    this.route("dp-cycle");
    this.route("adverse-event");
    this.route("fact-lym");
    this.route("feeling-evas");
    this.route("medical-event");
    this.route("das28");
    this.route("pr-activity");
    this.route("hemodialysis-session");
    this.route("scale-surveys");
    this.route("diabguide-recommendation");
    this.route("anxiety");
    this.route("ctcae");
    this.route("ctcae-inter-treatment");
    this.route("ctcae-pre-treatment");
    this.route("rch-survey");
    this.route("crohn-survey");
  });
}
