import Route from "@ember/routing/route";
import { service } from "@ember/service";
import JournalEventModel from "core/models/journal-event";
import Patient from "core/models/patient";
import FetcherService from "core/services/fetcher";
import formatToISO8601Date from "core/utils/format-to-iso8601-date";
import { setItemInLocalStorage } from "core/utils/use-local-storage";
import dayjs from "dayjs";

export const patientDashboardLastRouteKey = "last-patient-dashboard-route";

export default class DashboardPatientIndexRoute extends Route {
  @service declare fetcher: FetcherService;

  jesToFetchFromLastYear = [
    "AnxietyFilledJournalEvent",
    "BioExamExpectedDosageChangedJournalEvent",
    "BioExamFrequencyChangedJournalEvent",
    "BiologicalActRefundedJournalEvent",
    "CapillarityBloodGlucoseMeasuredJournalEvent",
    "CrohnSurveyFilledJournalEvent",
    "CtcaeFilledJournalEvent",
    "FeelingEvasDeclaredJournalEvent",
    "HeightMeasuredJournalEvent",
    "InterTreatmentFilledJournalEvent",
    "KfreComputedJournalEvent",
    "MedicalActRefundedJournalEvent",
    "MedicalImagingRefundedJournalEvent",
    "OkChimioSnapshotCapturedJournalEvent",
    "OxygeneSaturationDeclaredJournalEvent",
    "PreTreatmentFilledJournalEvent",
    "RchSurveyFilledJournalEvent",
    "RespiratoryRateMeasuredJournalEvent",
    "TemperatureMeasuredJournalEvent",
    "TreatmentHistoryChangedJournalEvent",
    "VemsDemmDeclaredJournalEvent",
    "WeightMeasuredJournalEvent"
  ];

  async model() {
    const patient = this.modelFor(
      "medics-web.protected.patients.patient"
    ) as Patient;

    /*******************************************************
     * JE types for which only 1 year of historic is needed *
     ********************************************************/
    const journalEventsFromLastYearReq =
      JournalEventModel.patientJournalEventsRequest(
        this.fetcher,
        patient.id,
        this.jesToFetchFromLastYear,
        [
          "BloodPressureSessionStartedJournalEvent",
          "BloodPressureMeasuredJournalEvent",
          "DrugsAndDevicesRefundedJournalEvent"
        ],
        dayjs().subtract(1, "y").toDate()
      );

    const consultationPlannedInTheFuture = this.fetcher.loadRecords(
      "consultation-planned-journal-event",
      {
        filter: {
          patient_id: patient.id,
          occurred_at_gte: formatToISO8601Date(new Date())
        },
        include: "creator"
      }
    );

    const bloodPressureJesReq = this.fetcher.loadRecords(
      "blood-pressure-session-started-journal-event",
      {
        filter: {
          patient_id: patient.id,
          occurred_at_gte: formatToISO8601Date(
            dayjs().subtract(1, "y").toDate()
          )
        },
        include: "blood-pressure-measured-journal-events"
      }
    );

    const drugsAndDevicesRefundedJournalEvents = this.fetcher.loadRecords(
      "drugs-and-devices-refunded-journal-event",
      {
        filter: {
          patient_id: patient.id,
          occurred_at_gte: formatToISO8601Date(
            dayjs().subtract(1, "y").toDate()
          )
        },
        include:
          "medication-dispenses.medication-presentation.medication-specialty"
      }
    );

    await Promise.all([
      journalEventsFromLastYearReq,
      consultationPlannedInTheFuture,
      bloodPressureJesReq,
      drugsAndDevicesRefundedJournalEvents
    ]);

    return patient;
  }

  activate() {
    setItemInLocalStorage(
      patientDashboardLastRouteKey,
      "medics-web.protected.patients.patient.dashboard.main"
    );
  }
}
