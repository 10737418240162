import Store from "@ember-data/store";
import { action } from "@ember/object";
import Route from "@ember/routing/route";
import RouterService from "@ember/routing/router-service";
import Transition from "@ember/routing/transition";
import { service } from "@ember/service";
import JournalEvent from "core/models/journal-event";
import Patient from "core/models/patient";
import TherapeuticalArea from "core/models/therapeutical-area";
import CableService from "core/services/cable";
import ConfigurationService from "core/services/configuration";
import FetcherService from "core/services/fetcher";
import SemeiaSessionService from "core/services/session";
import formatToISO8601Date from "core/utils/format-to-iso8601-date";
import dayjs from "dayjs";
import Controller from "./dashboard/main/feature/component/edition/controller";

export default class PatientRoute extends Route<Patient> {
  @service declare configuration: ConfigurationService;
  @service declare router: RouterService;
  @service declare fetcher: FetcherService;
  @service declare store: Store;
  @service declare cable: CableService;
  @service declare session: SemeiaSessionService;

  // FIXME check which JEs need file attachments
  jesToFetch = [
    "CareGiverAddedJournalEvent",
    "ConnectedDeviceLinkedJournalEvent",
    "CompoundSurveyCompletedJournalEvent",
    "DeviceOrderedJournalEvent",
    "EtapesPrescribedJournalEvent",
    "GenericPrescriptionUploadedJournalEvent",
    // MentalWise
    "SurveyRequestedJournalEvent",
    "DisorderHistoryDeclaredJournalEvent",
    "BdiIiDeclaredJournalEvent",
    "FastDeclaredJournalEvent",
    "QidsDeclaredJournalEvent",
    "PhqNineDeclaredJournalEvent",
    "YmrsDeclaredJournalEvent",
    "ThreeMonthsGoalsJournalEvent",
    // Rhumatowise
    "Das28VsComputedJournalEvent",
    "Das28CrpComputedJournalEvent",
    "Das28SdaiComputedJournalEvent",
    "Das28CdaiComputedJournalEvent",
    // Diabetowise
    "DiabguideRecommendationComputedJournalEvent"
  ];

  // According to this rule, this.controller must not be used:
  // https://github.com/ember-cli/eslint-plugin-ember/blob/master/docs/rules/no-controller-access-in-routes.md
  myController: Controller | null = null;
  beforeModel(transition) {
    if (
      transition.targetName === "medics-web.protected.patients.patient.index"
    ) {
      this.router.transitionTo(
        "medics-web.protected.patients.patient.dashboard"
      );
    }
  }

  async model(params) {
    // JE with file attachments
    const consultationReportWrittenJournalEvents = this.fetcher.loadRecords(
      "consultation-report-written-journal-event",
      {
        filter: {
          patient_id: params.patient_id,
          occurred_at_gte: formatToISO8601Date(
            dayjs().subtract(1, "y").toDate()
          )
        },
        include: "creator,file-attachments"
      }
    );

    const bioExamResultsReceivedJournalEvents = this.fetcher.loadRecords(
      "bio-exam-results-received-journal-event",
      {
        filter: {
          patient_id: params.patient_id,
          occurred_at_gte: formatToISO8601Date(
            dayjs().subtract(1, "y").toDate()
          )
        },
        include: "creator,file-attachments"
      }
    );

    const medicalEventJournalEvents = this.fetcher.loadRecords(
      "medical-event-journal-event",
      {
        filter: {
          patient_id: params.patient_id,
          occurred_at_gte: formatToISO8601Date(
            dayjs().subtract(1, "y").toDate()
          )
        },
        include: "creator,file-attachments"
      }
    );

    const treatmentPrescriptionChangedReq = this.fetcher.loadRecords(
      "treatment-prescription-changed-journal-event",
      {
        filter: {
          patient_id: params.patient_id
        },
        include:
          "recommended-therapeutical-classes,not-recommended-therapeutical-classes"
      }
    );

    /*******************************************************
     * JE types for which only the last JE is needed are    *
     *                directly included here                *
     ********************************************************/
    const patientReq = this.fetcher.loadRecord("patient", params.patient_id, {
      include: [
        "medics.health-centres",
        "health-centre",
        "health-centre.prescription-configuration.bio-exam-report-templates.bio-exam-orders",
        "health-centre.surveys.questions",
        "medical-act-frequency-changed-journal-event",
        "medical-history-changed-journal-event",
        "patient-enrolled-journal-event",
        "patient-disenrolled-journal-event",
        "comorbidities-and-allergies-changed-journal-event",
        "adherence-prediction-computed-journal-event",
        "hospitalisation-risk-prediction-computed-journal-event",
        "app-usage-consent-updated-journal-event",
        "tls-consent-updated-journal-event",
        "pro-irc-status-updated-journal-event",
        "dmp-access-consent-updated-journal-event",
        "data-sharing-consent-updated-journal-event",
        "spirometer-associated-journal-event",
        "compound-survey-frequencies-changed-journal-event",
        "patient-information-transmitted-journal-event",
        "crisis-plan-declared-journal-event",
        "digital-care-declared-journal-event",
        "jobs-declared-journal-event",
        "lifestyle-declared-journal-event",
        "referent-lab-updated-journal-event",
        "mrc-replacement-journal-event"
      ].join(",")
    });

    // Specific case to return the last survey-completed-journal-event of the needed types
    const lifestyleSurveys = this.fetcher.loadRecords(
      "survey-completed-journal-event",
      {
        filter: {
          patient_id: params.patient_id,
          last_of_type: "lifestyle/edition"
        }
      }
    );
    const personalBackgroundSurveys = this.fetcher.loadRecords(
      "survey-completed-journal-event",
      {
        filter: {
          patient_id: params.patient_id,
          last_of_type: "personal-background/form"
        }
      }
    );

    /*******************************************************
     * JE types for which all JEs are needed are fetch here *
     ********************************************************/

    const journalEventsReq = JournalEvent.patientJournalEventsRequest(
      this.fetcher,
      params.patient_id,
      this.jesToFetch,
      ["AlertTriggeredJournalEvent"]
    );

    const deviceAcquiredReqJes = this.fetcher.loadRecords(
      "connected-device-acquired-journal-event",
      {
        filter: { patient_id: params.patient_id },
        include: "device-ordered-journal-event"
      }
    );

    const deviceRefusedReqJes = this.fetcher.loadRecords(
      "device-order-refused-journal-event",
      {
        filter: { patient_id: params.patient_id },
        include: "device-ordered-journal-event"
      }
    );

    const notifs = this.fetcher.loadRecords(
      "notification-triggered-journal-event",
      {
        filter: {
          patient_id: params.patient_id,
          non_processed: "",
          for_current_medic: ""
        },
        include: "triggering-journal-event"
      }
    );

    const alerts = this.fetcher.loadRecords("alert-triggered-journal-event", {
      filter: {
        patient_id: params.patient_id,
        non_processed: "",
        for_current_medic: ""
      },
      include:
        "triggering-journal-event,comment-written-journal-events.creator,alert-ongoing-journal-event.creator"
    });

    const tasks = this.fetcher.loadRecords("task-planned-journal-event", {
      filter: { patient_id: params.patient_id },
      include: [
        "treatment-history-changed-journal-event",
        "task-processed-journal-event",
        "creator"
      ].join(",")
    });

    const recursiveTasks = this.fetcher.loadRecords(
      "recursive-task-planned-journal-event",
      {
        filter: { patient_id: params.patient_id },
        include: ["treatment-history-changed-journal-event", "creator"].join(
          ","
        )
      }
    );

    const consultations = this.fetcher.loadRecords(
      "consultation-planned-journal-event",
      {
        filter: { patient_id: params.patient_id },
        include: "treatment-history-changed-journal-event"
      }
    );

    const tlsPrescriptions = this.fetcher.loadRecords(
      "tls-prescribed-journal-event",
      {
        filter: { patient_id: params.patient_id },
        include: "creator"
      }
    );

    const ParamedicalCarePrescribedJournalEvent = this.fetcher.loadRecords(
      "paramedical-care-prescribed-journal-event",
      {
        filter: { patient_id: params.patient_id },
        include: "creator,file-attachments"
      }
    );
    const BioExamPrescribedJournalEvent = this.fetcher.loadRecords(
      "bio-exam-prescribed-journal-event",
      {
        filter: { patient_id: params.patient_id },
        include: "creator,file-attachments"
      }
    );
    const MedicationPrescribedJournalEvent = this.fetcher.loadRecords(
      "medication-prescribed-journal-event",
      {
        filter: { patient_id: params.patient_id },
        include: "creator,file-attachments"
      }
    );
    const MedicalImagingPrescribedJournalEvent = this.fetcher.loadRecords(
      "medical-imaging-prescribed-journal-event",
      {
        filter: { patient_id: params.patient_id },
        include: "creator,file-attachments"
      }
    );
    const fileUploadedJournalEvent = this.fetcher.loadRecords(
      "file-uploaded-journal-event",
      {
        filter: { patient_id: params.patient_id },
        include: "creator,file-attachments"
      }
    );

    /*******************************************************
     *           Other resources are fecthed here           *
     ********************************************************/

    const observationReq = this.fetcher.loadRecords("loinc", {
      filter: { patient_id: params.patient_id }
    });

    const orderReq = this.fetcher.loadRecords("bio-exam-order", {
      filter: { patient_id: params.patient_id }
    });

    const [patient] = await Promise.all([
      patientReq,
      journalEventsReq,
      orderReq,
      observationReq,
      deviceAcquiredReqJes,
      deviceRefusedReqJes,
      notifs,
      alerts,
      tasks,
      recursiveTasks,
      tlsPrescriptions,
      consultations,
      lifestyleSurveys,
      personalBackgroundSurveys,
      ParamedicalCarePrescribedJournalEvent,
      BioExamPrescribedJournalEvent,
      MedicationPrescribedJournalEvent,
      MedicalImagingPrescribedJournalEvent,
      consultationReportWrittenJournalEvents,
      bioExamResultsReceivedJournalEvents,
      medicalEventJournalEvents,
      treatmentPrescriptionChangedReq,
      fileUploadedJournalEvent
    ]);

    await this.fetcher.loadRecords("loinc", {
      filter: {
        loinc: (
          patient.therapeuticalArea as TherapeuticalArea
        ).defaultBioExams.map(exam => exam.id)
      }
    });

    const bioExamFqcyJes = this.store.peekAll(
      "bio-exam-frequency-changed-journal-event"
    );

    await Promise.all(
      bioExamFqcyJes.map(je => {
        return this.fetcher.loadRecords("loinc", {
          filter: { loinc: je.loincs },
          include: "bio-exam-order"
        });
      })
    );

    // I don't know ?
    await this.fetcher.loadRecords("dmp-document-retrieved-journal-event", {
      filter: { patient_id: params.patient_id },
      include: "file-attachments"
    });

    if (patient.hasDiabetoPathology)
      await this.fetcher.loadRecords("medication-therapeutical-group");

    return patient;
  }

  async afterModel(model) {
    await this.configuration.fetchConfigurationsForPatient(model);
    this.cable.subscribeToReloadChannel(model);
  }

  setupController(
    controller: Controller,
    model: Patient,
    transition: Transition
  ) {
    super.setupController(controller, model, transition);
    this.myController = controller;
  }

  @action
  willTransition(transition) {
    if (!this.myController) return;

    if (
      transition.from?.name ===
        "medics-web.protected.patients.patient.dashboard.main.feature.component.index" ||
      transition.from?.name ===
        "medics-web.protected.patients.patient.dashboard.main.feature.component.edition"
    ) {
      // Don't scroll to top when closing the modal.
      this.myController.preserveScrollPosition = true;
    } else {
      this.myController.preserveScrollPosition = false;
    }
  }

  deactivate() {
    this.cable.unsubscribeToReloadChannel(
      this.modelFor("medics-web.protected.patients.patient") as Patient
    );
  }
}
